import React, { ReactElement } from "react";
// import Image from "next/image";
import styles from "./styles.module.scss";
import FeatureList from "@components/shared/featureList";
import ScrollToTop from "@components/shared/scrollToTop";
import Pills from "@components/vehicleinjury/icons/Pills";
import Pain from "@components/vehicleinjury/icons/Pain";
import Wallet from "@components/vehicleinjury/icons/Wallet";
import House from "@components/vehicleinjury/icons/House";
import Tie from "@components/vehicleinjury/icons/Tie";
import Ambulance from "@components/vehicleinjury/icons/Ambulance";
import Therapy from "@components/vehicleinjury/icons/Therapy";
import Stress from "@components/vehicleinjury/icons/Stress";

export default function WhatToGain(): ReactElement {
    const points = [
        {
            icon: <Pills />,
            description: "All kinds of medical bills related to your injury",
        },
        {
            icon: <Pain />,
            description: "Pain and suffering the accident caused you",
        },
        {
            icon: <Wallet />,
            description: "Wages lost from the time you were not able to work",
        },
        {
            icon: <House />,
            description:
                "Damage to your properties, including your car and items inside the vehicle",
        },
        {
            icon: <Tie />,
            description: "Lost opportunities for employment",
        },
        {
            icon: <Ambulance />,
            description: "Costs of ambulance services",
        },
        {
            icon: <Therapy />,
            description: "Physical therapy costs",
        },
        {
            icon: <Stress />,
            description: "Anxiety and stress",
        },
    ];

    return (
        <section
            className={`max-w-7xl mx-auto pt-4 ${styles.container}`}
            style={{
                counterReset: "section",
            }}
        >
            <FeatureList
                featureList={points}
                mainTitle="What Can I Gain From a Car Crash Lawsuit?"
                mainDescription="A car accident lawyer can help you get compensation for:"
                classes={{
                    mainTitleClasses: ` font-bold text-2xl text-center   lg:text-4xl  `,
                    innerSectionClasses:
                        "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-[34px]",
                    oneBoxClasses: ` text-center  p-6  ${styles.item}`,
                    iconClasses:
                        "flex justify-center mx-auto block w-[fit-content]",

                    mainDescriptionClasses:
                        " text-lg mt-4 text-center lg:text-2xl",
                    mainSectionClasses: "mx-auto  mt-12",
                    descriptionClasses: "text-base text-center",
                }}
                colors={{
                    mainTitleColor: "#be1e20",
                }}
            />
            <div className="flex justify-center items-center mb-16  py-8 md:py-16">
                <ScrollToTop
                    colors={{
                        bg: "#be1e20",
                        text: "white",
                    }}
                    className="rounded"
                >
                    Get My Free Lawsuit Case Review
                </ScrollToTop>
            </div>
        </section>
    );
}
