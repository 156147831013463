import React, { ReactElement } from "react";
import SideImage from "../sideImage";

export default function AccidentLawyer(): ReactElement {
    return (
        <section className="max-w-7xl mx-auto ">
            <SideImage
                title={"Why Hire a Car Accident Lawyer?"}
                content={[
                    "Personal injury law firms assist car crash victims. Consult a lawyer immediately. Lawyers can clarify your rights and duties under state law. They negotiate with insurance companies, which always offer the lowest compensation. Our lawyers will fight to get you what you deserve.",

                    "Even minor injuries can have unexpected long-term implications. Your medical bills may skyrocket. After an accident, worry can make daily tasks difficult, and recovery may take longer than expected, changing your life as you once knew it. You don't have to do this alone. Fill out our form now to find the best car accident lawyer.",
                ]}
                image={
                    "/caraccidentauthority/assets/images/accident_lawyer.jpg"
                }
                imageClassName={
                    "md:w-[580px] md:h-[380px] w-[300px] h-[200px] mt-3"
                }
            />
        </section>
    );
}
