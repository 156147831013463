import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import ScrollToTop from "@components/shared/scrollToTop";

export default function Settlement(): ReactElement {
    return (
        <section
            className={`max-w-7xl mx-auto py-12 md:py-24 ${styles.container}`}
            style={{
                counterReset: "section",
            }}
        >
            <div
                className={`lg:w-1/2 ml-auto ${styles.inner} p-6 md:p-[76px] text-center my-10`}
            >
                <h2>What Is an Auto Accident Settlement?</h2>
                <p>
                    A settlement is an agreement between the person who caused
                    the car crash or their insurance company and the person hurt
                    in the accident. This could happen at any point before or
                    during a trial.
                </p>
                <p>
                    During talks, one side could ask the other to pay a fee.
                    Settlements are usually better than going to court because
                    they save everyone time, money, and work. If they can't
                    reach a deal, the matter must go to trial.
                </p>
                <div className="flex justify-center items-center   mt-8 ">
                    <ScrollToTop
                        colors={{
                            bg: "white",
                            text: "#be1e20",
                        }}
                        className="rounded"
                    >
                        Get My Free Lawsuit Case Review
                    </ScrollToTop>
                </div>
            </div>
        </section>
    );
}
