import React from "react";
import { useDispatch } from "react-redux";
import { setModal } from "@redux/shared/actions";

const Disclaimer = () => {
    const dispatch = useDispatch();

    return (
        <section className="max-w-7xl mx-auto lg:mt-28 mt-12 mb-8 lg:pb-7 text-[11px] text-center text-[#999] italic ">
            <p className="mt-2">
                Advertising is paid for by participating attorneys in a joint
                advertising program, licensed to practice law only in
                California. A complete list of joint advertising attorneys can
                be found
                <span
                    className="text-[blue] cursor-pointer"
                    onClick={() =>
                        dispatch(
                            setModal({ active: true, content: "disclaimer" }),
                        )
                    }
                >
                    {" "}
                    here
                </span>
                . You can request an attorney by name. We are not a law firm or
                an attorney referral service. This advertisement is not legal
                advice and is not a guarantee or prediction of the outcome of
                your legal matter. Every case is different. The outcome depends
                on the laws, facts, and circumstances unique to each case.
                Hiring an attorney is an important decision that should not be
                based solely on advertising. Request free information about your
                attorney's background and experience. This advertising does not
                imply a higher quality of legal services than that provided by
                other attorneys. This advertising does not imply that the
                attorneys are certified specialists or experts in any area of
                law. No legal services will be provided unless a signed
                agreement between the client and the attorney exists. We use
                cookies to personalize content and to analyze our traffic. We
                also share information about your use of our site with our
                analytics partners, who may combine it with other information
                you've provided or collected from your use of their services.
                You consent to our cookies if you continue to use our website.
            </p>
        </section>
    );
};

export default Disclaimer;
