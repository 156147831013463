import React, { ReactElement } from "react";
import SideImage from "../sideImage";

export default function SuitTime(): ReactElement {
    return (
        <section className="max-w-7xl mx-auto ">
            <SideImage
                title={"How Long Do You Have to File Your Suit?"}
                content={[
                    'Car accident lawsuit deadlines vary by state. The "statute of limitations" limits the time parties have to sue. Depending on state law, which a lawyer can help you navigate, this might take one to six years.',
                    "Personal injury cases and insurance claims have different timeframes. Check with your insurance company about your policy.",
                ]}
                image={"/caraccidentauthority/assets/images/court.jpg"}
                imageClassName={
                    "md:w-[580px] md:h-[380px] w-[300px] h-[200px] mt-3"
                }
                flip
            />
        </section>
    );
}
